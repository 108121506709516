<template>
  <b-modal id="ContractFormInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/contracts.svg" class="icon-lg" />
        {{ $t("ContractForms.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormNameAr"
        :title="$t('ContractForms.nameAr')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormNameEn"
        :title="$t('ContractForms.nameEn')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormNameUnd"
        :title="$t('ContractForms.nameUnd')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormDescriptionAr"
        :title="$t('ContractForms.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormDescriptionEn"
        :title="$t('ContractForms.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormDescriptionUnd"
        :title="$t('ContractForms.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="contractForm.contractFormNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["contractForm"],
  methods: {},
};
</script>
