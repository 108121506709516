<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="
          checkPrivilege(
            usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
              .additionalSettingsAdd
          )
        "
      >
        <span class="fab-label">
          <router-link :to="{ name: 'ContractFormAdd' }" class="">
            {{ $t("ContractForms.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'ContractFormAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="CustomBottomSheet">
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />

        <!-- <CustomSelectBox
          :className="'col-md-12'"
          :id="'contractFormTypeToken'"
          :value="filterData.contractFormTypeToken"
          :options="contractFormTypeTokenOptions"
          v-on:changeValue="filterData.contractFormTypeToken = $event"
          :title="$t('ContractForms.contractFormTypeSelect')"
          :imgName="'type.svg'"
        /> -->
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
// import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import privilegeMixin from "@/utils/privilege-mixin";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";

export default {
  mixins: [privilegeMixin, createToastMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      filterData: this.theFilterData,
      contractFormTypeTokenOptions: [],
    };
  },
  components: {
    CustomInput,
    // CustomSelectBox,
  },
  methods: {
    setDataMultiLang,
    open() {
      this.$refs.CustomBottomSheet.open();
    },
    close() {
      this.$refs.CustomBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },

    // async getDialogOfContractFormTypes() {
    //   this.isLoading = true;
    //   this.contractFormTypeTokenOptions = [];
    //   this.contractFormTypeTokenOptions.push({
    //     value: "",
    //     text: this.$t("ContractForms.contractFormTypeSelect"),
    //   });
    //   let itemsData = this.$store.getters.userData.constantLists.listContractFormType;
    //   for (let item in itemsData) {
    //     this.contractFormTypeTokenOptions.push({
    //       value: itemsData[item]["itemToken"],
    //       text: this.setDataMultiLang(
    //         this.language,
    //         itemsData[item]["itemNameAr"],
    //         itemsData[item]["itemNameEn"]
    //       ),
    //     });
    //   }

    //   this.isLoading = false;
    // },

    checkPrivilege,
  },
  async created() {
    // this.getDialogOfContractFormTypes();
  },
};
</script>
